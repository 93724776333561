var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableWrapper",staticClass:"k-table-wrapper"},[(_vm.dataLoading)?[_c('p',[_c('KCircularLoader')],1)]:[(!_vm.isTableEmpty)?_c('table',{staticClass:"k-table",attrs:{"role":"grid"}},[(_vm.caption)?_c('caption',{staticClass:"visuallyhidden"},[_vm._v("\n        "+_vm._s(_vm.caption)+"\n      ")]):_vm._e(),_vm._v(" "),_c('thead',[_c('tr',{ref:"stickyHeader"},_vm._l((_vm.headers),function(header,index){
var _obj;
return _c('th',{key:index,ref:'header-' + index,refInFor:true,class:( _obj = {}, _obj[_vm.$computedClass(_vm.coreOutlineFocus)] = true, _obj.sortable = _vm.isColumnSortable(index), _obj['sticky-header'] =  true, _obj['sticky-column'] =  index === 0, _obj ),style:([_vm.getHeaderStyle(header),
                     _vm.isColumnSortActive(index) ? { color: _vm.$themeBrand.primary.v_500 } : { color: _vm.$themePalette.grey.v_800 },
                     { backgroundColor: _vm.$themePalette.white } ,
                     _vm.isColumnFocused(index) ? { backgroundColor: _vm.$themePalette.grey.v_100 } : {},
                     { textAlign: _vm.getTextAlign(header.dataType) }]),attrs:{"tabindex":"0","aria-sort":_vm.isColumnSortable(index) ? _vm.getAriaSort(index) : null,"role":"columnheader","data-focus":"true","aria-colindex":index + 1},on:{"click":function($event){_vm.sortable ? _vm.handleSort(index) : null},"keydown":function($event){return _vm.handleKeydown($event, -1, index)}}},[_vm._t("header",function(){return [_vm._v("\n              "+_vm._s(header.label)+"\n            ")]},{"header":header,"colIndex":index}),_vm._v(" "),(_vm.isColumnSortable(index))?_c('span',{staticClass:"sort-icon"},[(_vm.isColumnSortActive(index) && _vm.sortOrder === _vm.SORT_ORDER_ASC)?_c('span',[_c('KIcon',{attrs:{"icon":"dropup","color":_vm.isColumnSortActive(index) ? _vm.$themeBrand.primary.v_600 : _vm.$themePalette.grey.v_800}})],1):(_vm.isColumnSortActive(index) && _vm.sortOrder === _vm.SORT_ORDER_DESC)?_c('span',[_c('KIcon',{attrs:{"icon":"dropdown","color":_vm.isColumnSortActive(index) ? _vm.$themeBrand.primary.v_600 : _vm.$themePalette.grey.v_800}})],1):_c('span',[_c('KIcon',{attrs:{"icon":"sortColumn","color":_vm.$themePalette.grey.v_800}})],1)]):_vm._e()],2)}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.finalRows),function(row,rowIndex){return _c('tr',{key:rowIndex,style:(_vm.getRowStyle(rowIndex)),on:{"mouseover":function($event){return _vm.handleRowMouseOver(rowIndex)},"mouseleave":_vm.handleRowMouseLeave}},_vm._l((row),function(col,colIndex){return _c('KTableGridItem',{key:colIndex,ref:'cell-' + rowIndex + '-' + colIndex,refInFor:true,class:{
              'sticky-column': colIndex === 0,
            },style:(_vm.getCellStyle(rowIndex, colIndex)),attrs:{"content":col,"dataType":_vm.headers[colIndex].dataType,"minWidth":_vm.headers[colIndex].minWidth,"width":_vm.headers[colIndex].width,"rowIndex":rowIndex,"colIndex":colIndex,"textAlign":_vm.getTextAlign(_vm.headers[colIndex].dataType),"data-focus":"true","role":"gridcell","aria-colindex":colIndex + 1},on:{"keydown":function($event){return _vm.handleKeydown($event, rowIndex, colIndex)}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_vm._t("cell",function(){return [_vm._v("\n                "+_vm._s(slotProps.content)+"\n              ")]},{"content":slotProps.content,"rowIndex":rowIndex,"colIndex":colIndex,"row":row})]}}],null,true)})}),1)}),0)]):_c('div',{staticClass:"empty-message"},[_vm._v("\n      "+_vm._s(_vm.emptyMessage)+"\n    ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }