var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DocsPageTemplate',{attrs:{"apiDocs":""}},[_c('DocsPageSection',{attrs:{"title":"Overview","anchor":"#overview"}},[_c('p',[_vm._v("\n      The "),_c('code',[_vm._v("KListWithOverflow")]),_vm._v(" component is useful when we want a horizontal list that is responsive and adaptable to the container size. The list only shows the items that fit on the container element. When items exceed the available space, the component seamlessly integrates a \"see more\" button to show additional items.\n    ")])]),_vm._v(" "),_c('DocsPageSection',{attrs:{"title":"Example","anchor":"#example"}},[_c('p',[_vm._v("\n      When a number of items fit within the screen boundaries, the list will be displayed without any changes. For example, since there are only 3 items here, we can see the entire list without problems.\n    ")]),_vm._v(" "),_c('div',{staticClass:"klist-example"},[_c('DocsShow',{attrs:{"block":""}},[_c('KListWithOverflow',{attrs:{"items":_vm.getItems(3)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('KIconButton',{attrs:{"tooltip":item.label,"icon":item.icon}})]}},{key:"more",fn:function(ref){
var overflowItems = ref.overflowItems;
return [_c('KIconButton',{attrs:{"tooltip":"More","icon":"optionsVertical","appearance":"flat-button","primary":false},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('KDropdownMenu',{attrs:{"options":overflowItems}})]},proxy:true}],null,true)})]}}])})],1)],1),_vm._v(" "),_c('p',[_vm._v("\n      But if the list becomes very long, and does not fit within the screen, then the overflowed items will be cut, and an element will be placed to show more. For example, here are 12 items in the list.\n    ")]),_vm._v(" "),_c('div',{staticClass:"klist-example"},[_c('DocsShow',{attrs:{"block":""}},[_c('KListWithOverflow',{attrs:{"items":_vm.getItems(12)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('KIconButton',{attrs:{"tooltip":item.label,"icon":item.icon}})]}},{key:"more",fn:function(ref){
var overflowItems = ref.overflowItems;
return [_c('KIconButton',{attrs:{"tooltip":"More","icon":"optionsVertical","appearance":"flat-button","primary":false},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('KDropdownMenu',{attrs:{"options":overflowItems}})]},proxy:true}],null,true)})]}}])})],1)],1),_vm._v(" "),_c('p',[_vm._v("\n      The following is a code example to render the above examples:\n    ")]),_vm._v(" "),_c('DocsShowCode',{attrs:{"language":"html"}},[_vm._v("\n      <KListWithOverflow\n        :items=\"items\"\n      >\n\n        <template #item=\"{ item }\">\n          <KIconButton\n            :tooltip=\"item.label\"\n            :icon=\"item.icon\"\n          />\n        </template>\n\n        <template #more=\"{ overflowItems }\">\n          <KIconButton\n            tooltip=\"More\"\n            icon=\"optionsVertical\"\n          >\n            <template #menu>\n              <KDropdownMenu\n                :options=\"overflowItems\"\n              />\n            </template>\n          </KIconButton>\n        </template>\n\n      </KListWithOverflow>\n    ")]),_vm._v(" "),_c('DocsShowCode',{attrs:{"language":"javascript"}},[_vm._v("\n      data() {\n        return {\n          items: [\n            { label: \"Item 1\", icon: \"edit\" },\n            { label: \"Item 2\", icon: \"edit\" },\n            { label: \"Item 3\", icon: \"edit\" },\n            // ...\n            { label: \"Item 12\", icon: \"edit\" },\n          ]\n        };\n      },\n    ")]),_vm._v(" "),_c('p',[_vm._v("\n      You can also use dividers within the list by passing a "),_c('code',[_vm._v("{ type: \"divider\" }")]),_vm._v(" object, and set a #divider slot.\n      Note that the visible list will not end with a divider. And a divider object will not be passed as a first overflowed item.\n    ")]),_vm._v(" "),_c('div',{staticClass:"klist-example"},[_c('DocsShow',{attrs:{"block":""}},[_c('KListWithOverflow',{attrs:{"items":_vm.getItems(12, 2)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('KIconButton',{attrs:{"tooltip":item.label,"icon":item.icon}})]}},{key:"more",fn:function(ref){
var overflowItems = ref.overflowItems;
return [_c('KIconButton',{attrs:{"tooltip":"More","icon":"optionsVertical","appearance":"flat-button","primary":false},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('KDropdownMenu',{attrs:{"options":overflowItems}})]},proxy:true}],null,true)})]}},{key:"divider",fn:function(){return [_c('div',{staticClass:"divider-wrapper"},[_c('div',{style:(_vm.dividerStyle)})])]},proxy:true}])})],1)],1),_vm._v(" "),_c('p',[_vm._v("\n      To use dividers, you can include a divider object in the items list, and add a #divider slot.\n    ")]),_vm._v(" "),_c('DocsShowCode',{attrs:{"language":"html"}},[_vm._v("\n      <KListWithOverflow\n        :items=\"items\"\n      >\n        <!-- ... -->\n        <template #divider>\n          <div class=\"divider-wrapper\">\n            <div :style=\"dividerStyle\"></div>\n          </div>\n        </template>\n      </KListWithOverflow>\n    ")]),_vm._v(" "),_c('DocsShowCode',{attrs:{"language":"javascript"}},[_vm._v("\n      data() {\n        return {\n          items: [\n            { label: \"Item 1\", icon: \"edit\" },\n            { label: \"Item 2\", icon: \"edit\" },\n            { type: \"divider\" },\n            { label: \"Item 4\", icon: \"edit\" },\n            // ...\n          ]\n        };\n      },\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }