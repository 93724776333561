<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.178 4.154l5.177 5.523H7l5.177-5.523zM12.177 20.654L7 15.13h10.354l-5.177 5.524z" fill="#000"/></svg>

</template>


<script>

  export default {"name":"icon-804c4e229f501f72a23ba2f5a5cc42d5"}

</script>