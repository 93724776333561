import { render, staticRenderFns } from "./watch-shaded.vue?vue&type=template&id=1f6e76d6&"
import script from "./watch-shaded.vue?vue&type=script&lang=js&"
export * from "./watch-shaded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports